<template>
  <page-section
      width="800"
      background-color="white"
  >


    <!-- Categories -->
    <div class="d-flex justify-space-between align-center px-6"
         :class="[{'my-12' : !SM} , {'mt-5' : SM }, {'mb-12' : SM }]"
    >

      <h1 :style="`color : ${wsDARKER}; ${SM ? 'font-size : 24px' : ''}`">
        {{ $t('Blog') }}
      </h1>

      <div v-if="!SM" class="d-flex">
        <v-btn
            v-for="(item,i) in newsTypesSelect" :key="`button_${i}`"
            :color="wsACCENT"
            @click="selectedCategory = item"
            class="noCaps mr-2"
            :outlined="selectedCategory === item"
            :text="selectedCategory !== item"
        >{{ $t(item) }}</v-btn>
      </div>
      <ws-select
          v-else
          width="130px"
          v-model="selectedCategory"
          :items="newsTypesSelectItems"
          hide-details
      >

      </ws-select>

    </div>

    <!-- News -->
    <div :class="[{'px-6' : SM}]">
      <news-preview
          class="mt-8"
          v-for="(item,i) in news" :key="i"
          :entity="item"
      />
    </div>

    <div v-if="!isLast" class="d-flex justify-center pb-12">
      <ws-button
          @click="getNews(true)"
          label="loadMore"
      />
    </div>



  </page-section>
</template>

<script>
import {mapActions} from "vuex";
import newsPreview from "@/components/pages/homepage/news/UI/newsPreview";

export default {
  name: "HomeNews",
  components : {
    newsPreview
  },
  data() {
    return {
      isLast : false,
      offset : 0,
      selectedCategory : 'All',
      news : [],
      newsTypesSelect : [ 'All' , 'news.articles' ,  'news.updates' , 'news.streams' , 'news.mass_media' , 'news.community' ]
    }
  },
  computed : {
    newsTypesSelectItems() {
      let items = []
      this.newsTypesSelect.forEach( el=> {
        items.push({
          text : this.$t(el),
          value : el
        })
      })
      return items
    }
  },
  watch : {
    selectedCategory() {
      this.getNews()
    },
    LANG() {
      this.getNews()
    }

  },
  methods : {
    ...mapActions('news', [
          'GET_NEWS_MAIN_PUBLIC'
        ]
    ),
    async getNews(loadMore = false) {
      if ( !loadMore ) {
        this.news = []
      }
      let data = {
        category_id : this.selectedCategory,
        offset : this.news.length
      }

      let result = await this.GET_NEWS_MAIN_PUBLIC(data)
      if ( !result ) {
        return
      }
      if ( !loadMore ) {
        this.news = result.news
      } else {
        if ( result.news.length > 0 )
        result.news.forEach(el => {
          this.news.push(el)
        })
      }
      this.isLast = result.is_last || false

    }
  },
  async mounted(){
    this.getNews()
  }
}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>