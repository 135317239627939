<template>
  <ws-link
      :to="!entity.external_link && !!entity.alias && entity.has_content ? localeLink('news/' + entity.alias) : null"
      :href="entity.external_link || null "
      :new-window="entity.external_link"
  >
  <v-sheet
      max-width="782"
      :color="!isNotification ? backgroundColor : 'transparent'"
      class="wsRoundedHalf mb-12"
      :dark="darkCondition"
      :class="[
          {'py-8' : !isNotification && !SM },
          {'py-6' : !isNotification && SM }
      ]"
  >
    <v-row no-gutters  style="height: 100% !important" :style="!SM ? 'flex-wrap: nowrap;' : null" class="fill-height"  >

      <v-col :cols="SM ? '12' : null" v-if="isNotification" :class="!SM ? 'flex-grow-0 flex-shrink-0' : null ">
        <v-sheet
            :color="backgroundColor"
            min-width="171" width="171" height="116"
            class="d-flex align-center justify-center wsRoundedHalf"
        >
          <v-icon x-large :color="['light' , 'medium_light'].includes(this.entity.background_color) ? wsACCENT : 'white'">
            {{ entity.preview_icon || 'mdi-icon' }}
          </v-icon>
        </v-sheet>
      </v-col>

      <v-col :cols="SM ? '12' : null"  :style="!SM ? 'min-width: 100px; max-width: 100%;' : null" :class="!SM ? 'flex-grow-1 flex-shrink-0' : null">
        <v-sheet color="transparent"  width="100%"
                 :class="[
                     { 'px-6': SM && !isNotification},
                     { 'px-8' : !SM }
                 ]"
        >
          <h4 style="font-size: 16px; font-weight: 400"
              :style="`color : ${ !(entity.background_color ==='medium' || entity.background_color ==='accent') ? wsDARKLIGHT : 'white'}`"
              class="mb-2"
              :class="[{'mt-5' : SM && isNotification}]"
          >
            {{ PARSE_DATE(entity.date_published , false ,true) }} / {{ $t(entity.category_value ) }}
          </h4>

          <h2 :style="`color : ${!darkCondition ? wsDARKER : 'white'}`" v-if="!isNotification" v-html="entity.title" />
          <h4 :style="`color : ${!darkCondition ? wsDARKER : 'white'}`" v-else v-html="entity.title" />
          <ws-text-viewer class="nunito" font-size="18" :value="entity.preview" />

          <h4 v-if="entity.has_content && !!entity.alias"
              class="pointer hoverUnderline"
              :style="`color: ${wsATTENTION}`" >{{ $t('MoreDetails') }}
            <v-icon :color="wsATTENTION">mdi-arrow-right</v-icon>
          </h4>


        </v-sheet>
      </v-col>

    </v-row>
  </v-sheet>
  </ws-link>
</template>

<script>
export default {
  name: "newsPreview",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
    routerTag() {
      if (this.entity.external_link) {
        return 'a'
      }

      if (this.entity.has_content) {
        return 'router-link'
      }
      return 'span'
    },
    darkCondition() {
      return !['light' , 'medium_light'].includes(this.entity.background_color) && !this.isNotification
    },
    isNotification() {
      return this.entity.type === 'notification'
    },
    backgroundColor() {

      switch (this.entity.background_color) {
        case 'light' : return this.wsLIGHTCARD
        case "medium_light" : return this.wsLIGHTACCENT
        case "medium" : return this.wsDARKLIGHT
        case "dark" : return this.wsACCENT
        case "accent"  : return this.wsATTENTION
        default : return this.wsLIGHTCARD
      }
    },
  }
}
</script>

<style scoped>

</style>